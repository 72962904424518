
const timelineData = [
    {
        time: "2014",
        description: ' Embarked on my journey by enrolling in Computer Science School.',
        images: [],
    },
    {
        time: "Early years (2014-2016)",
        description: 'Eager to find my true passion within the realm of technology, I delved into various fields, seeking a challenge that would ignite my enthusiasm and steer me away from mundane routines. It was during this period that I discovered my deep fascination with the field of AI. The prospect of facing daily intellectual challenges and creating groundbreaking solutions that defied convention resonated with my aspirations. To equip myself with the necessary skills, I eagerly pursued online courses such as the Machine Learning Specialization by UW and the Machine Learning course by Andrew Ng.',
        images: [],
    },
    {
        time: "2018",
        description: 'This pivotal year marked the realization of the efforts I had invested in my AI journey. I achieved first place in a project-based machine learning and data science competition, a testament to my dedication and growing expertise. Notably, I developed a recommender system for articles utilizing a Doc2Vec-based model, showcasing my ability to harness cutting-edge technologies for practical applications.',
        images: [{ src: 'assets/images/wikilogia-award.png', alt: 'image 1' }],
    },
    // {
    //     time: "2019",
    //     description: 'Culminating my undergraduate studies with distinction, I undertook a graduation project that exemplified the depth of my knowledge and skills. With the utilization of LSTM/GRU recurrent neural networks, I successfully predicted stock market trends, earning a commendable grade of 98/100. This achievement further solidified my commitment to leveraging AI for insightful analysis and informed decision-making.',
    //     images: [{ src: 'assets/images/wikilogia-award.png', alt: 'image 1' }, { src: 'assets/images/its-heartland-award.jpeg', alt: "image 2" }],
    // },
    {
        time: "Late 2019",
        // description: 'As a graduate of the School of Computer Science, I stood equipped with a solid foundation and a hunger for real-world experiences. Transitioning into the professional realm, I sought opportunities that would refine my software and data engineering acumen. Through remote collaborations with European companies, I honed my expertise by developing web and mobile applications, and even ventured into the realm of web scraping.',
        description: 'As a graduate of the School of Computer Science, I sought real-world experiences to refine my software and data engineering skills. Through remote collaborations with European companies, I developed web and mobile applications and explored web scraping.',
        images: [],
    },
    {
        time: "Aug 2021",
        description: 'Embracing the pursuit of knowledge and growth, I embarked on an exciting new chapter as a graduate research assistant while pursuing an MSc in Electrical and Computer Engineering at the University of Oklahoma. This period granted me the privilege of conducting research in collaboration with the Oklahoma Department of Transportation. My primary focus involved analyzing and modeling traffic data derived from sensor-based and probe-based systems deployed throughout Oklahoma. In other works, I designed and implemented an Android IoT application to facilitate communication between an ODOT snowplow truck embedded system and a data server. Presently, I am dedicated to developing ML/DL models that leverage ODOT RWIS (Road and Weather Information System) weather data, combined with speed data, to accurately classify road surface conditions—an endeavor that has the potential to enhance road safety significantly.',
        images: [{ src: 'assets/images/ou-ece.png', alt: 'OU ECE Logo' }, { src: 'assets/images/its-heartland-award.jpeg', alt: "ITS Heartland Award" }],
    },
    {
        time: "Fall 2023",
        description: 'Looking ahead with excitement and anticipation, I am on track to graduate in the fall of this year, armed with a wealth of knowledge and practical experiences that have shaped me into a competent AI and software engineering professional. I hold in my heart a warm thank you for everyone who has been a part of this journey. Your unwavering support and belief in me have illuminated my path, enriching every step. As I embark on the next chapter, I carry forward the invaluable gift of your companionship and faith.',
        images: [{ src: "assets/images/graduation-commencement-2023.jpg", alt: "Graduation Commencement at OU-Tulsa 2023"}],
    },
];


export default timelineData;