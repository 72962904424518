const introParagraph = <>Hi, I'm Ali, <br /><br />
A computer engineer with over <strong>4 years</strong> of demonstrated experience in machine learning, data science, and full-stack software engineering. 
{/* I have a keen interest in the realms of machine learning, deep learning, and computer vision, particularly their applications in autonomous driving, robotics, and end-to-end systems. */}
<br/><br/>I just graduated with an <strong>MSc in ECE</strong> from the <strong>University of Oklahoma</strong>, where I worked as a graduate research assistant. 
My research focused on applied machine learning, data science, and time-series analysis of weather and transportation data.
I developed ML models to classify road weather surface conditions, as well as predict travel times on highways with over 90% accuracy, utilizing data from sensor systems. I also built an Android app for communication in snowplow trucks and worked with embedded systems and sensors. My work has earned awards at the 2022 ITS Heartland competition and the 2023 Oklahoma Transportation Research Day.

<br/><br/>In previous roles, I accumulated 2 years of experience in software and data engineering, developing mobile and web applications, building web scrapers, and acquiring exposure to software project management.
</>;


export default introParagraph;