import React from 'react';
import './IntroSection.scss';
import Image from '../atoms/Image';
import Name from '../molecules/Name';
import Paragraph from '../atoms/Paragraph';
// import textContent from '../../assets/textContent.json';
import ContactIcons from '../organisms/ContactIcons';
import introParagraph from '../../data/IntroData';

const IntroSection = () => {
    // const { paragraph } = textContent.introduction;

    return (
        <section className='introduction-section' aria-label="Introduction">
            <Image src="assets/images/me.jpg" alt="Personal picture of Mohamed Ali Habib" className="personal-pic"></Image>

            <div className='introduction-content'>
                <Name name="Mohamed Ali Habib" className="name-title" ariaLabel="name-title" />

                {/* <Paragraph className="introduction-paragraph">
                    Hi I’m Ali,<br /><br />I am a graduate research assistant at the University of Oklahoma pursuing a Master of Science in Electrical and Computer Engineering.<br />I am an engineer with a focus on software, machine learning, and data science. I thrive on solving complex problems and leveraging technology to create innovative solutions. I have a demonstrated experience in data science, machine learning, and software engineering.<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium, nibh in imperdiet ultricies, libero lorem euismod arcu, a aliquet arcu diam quis ipsum. Mauris felis turpis, tempus condimentum finibus eu, porta at felis. Phasellus consectetur, est sit amet tincidunt fermentum, lacus mauris volutpat est, non hendrerit eros quam eget neque. Praesent non ex dolor. Mauris tempor nulla purus, ac dictum nulla bibendum et. In dapibus, eros et scelerisque efficitur, nulla magna bibendum massa, sit amet vestibulum magna nibh eleifend purus. Maecenas egestas, libero vitae venenatis varius, orci diam vulputate lectus, nec consectetur lectus nulla vitae lectus. Nulla eu lacus elit.
                </Paragraph> */}
                <Paragraph className="introduction-paragraph">
                    {introParagraph}
                </Paragraph>

                <ContactIcons />
            </div>
        </section>
    );
};

export default IntroSection;