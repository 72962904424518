import React from 'react';
import Card from '../molecules/Card';
import Image from '../atoms/Image';
import Paragraph from '../atoms/Paragraph';
import { BiBrain } from 'react-icons/bi';
import Badge from '../molecules/Badge';
import { FaGithub, FaMedium } from 'react-icons/fa';
import IconAnchor from '../molecules/IconAnchor';
import './Project.scss';

const Project = ({ title, description, imageSrc, githubLink, articleLink, badges, affiliateImageSrc }) => {
    return (
        <Card className="project-card">

            {imageSrc && <Image src={imageSrc} alt="project" className="project-image" />}

            <div className='project-content'>
                <div className='badges-container'>
                    {affiliateImageSrc && (
                        <Image src={affiliateImageSrc} alt="affiliate-logo" className="project-affiliate" />
                    )}

                    {badges && badges.map((badge, index) => (
                        <Badge
                            icon={badge.icon}
                            label={badge.label}
                            className="project-badge"
                            key={index}
                        />
                    ))}
                    {/* <Badge icon={BiBrain} label="ML" className="project-badge" /> */}
                    {/* <Badge icon={BiBrain} label="ML" className="project-badge" />
                    <Badge icon={BiBrain} label="ML" className="project-badge" />
                    <Badge icon={BiBrain} label="ML" className="project-badge" /> */}


                </div>


                <h1 className='project-title'>{title}</h1>
                <div className='description-wrapper'>
                    {/* <Paragraph className='project-description'>{description}</Paragraph> */}
                    <div className='project-description'>{description}</div>
                </div>

            </div>
            {(githubLink || articleLink) && (
                <div className='project-links'>
                    {githubLink && <IconAnchor icon={<FaGithub />} link={githubLink} target="_blank" rel="noopener noreferrer" />}
                    {articleLink && <IconAnchor icon={<FaMedium />} link={articleLink} target="_blank" rel="noopener noreferrer" />}
                </div>
            )}
            {/* <div className='project-links'>
                {githubLink && <IconAnchor icon={<FaGithub />} link={githubLink} target="_blank" rel="noopener noreferrer" />}
                {articleLink && <IconAnchor icon={<FaMedium />} link={articleLink} target="_blank" rel="noopener noreferrer" />}
            </div> */}
        </Card>
    );
}

export default Project;