import React from 'react';
import Anchor from '../atoms/Anchor';
import Span from '../atoms/Span';

const NavigationItem = ({ href, title, children, active, target, rel, onMouseEnter, _key, onClick}) => {
    return (
        <li className={`nav-item ${active ? 'active' : ''}`} onMouseEnter={onMouseEnter} key={_key}>
        {/* <li> */}
            <Anchor href={href} target={target} rel={rel} title={title} className="nav-anchor" onClick={onClick}>
                <Span className="nav-item-text">{title}</Span>
            </Anchor>
        </li>
    );
}

export default NavigationItem;