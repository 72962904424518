import React from 'react';
import Card from '../molecules/Card';
// import IconAnchor from '../molecules/IconAnchor';
// import { FaEnvelope } from 'react-icons/fa';
// import SvgIcon from '../atoms/SvgIcon';
import Paragraph from '../atoms/Paragraph';
import './Skill.scss';
import Icon from '../atoms/Icon';

const Skill = ({ title, icon, content }) => {
    return (
        <Card className="skill-card" >

            <div className='skill-header'>
                {/* icon */}
                <Icon icon={icon} className="skill-icon" />
                {/* title */}
                <h1>{title}</h1>
            </div>

            {/* <div className='skill-content'> */}
                {/* text content (e.g., bullet points) */}
                {/* <Paragraph className='skill-content'>{content}</Paragraph> */}
                <div className='skill-content'>{content}</div>
            {/* </div> */}

        </Card>
    );
}

export default Skill;