import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({className, children}) => {
    return <p className={className}>{children}</p>;
};
 
Paragraph.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Paragraph;