import React from 'react';
import './Timeline.scss';
import Paragraph from '../atoms/Paragraph';
import Image from '../atoms/Image';
import timelineData from '../../data/TimelineData';


const Timeline = ({ _ref }) => {
    return (
        <section className='timeline'>

            {/* Sections: year, text, and images (optional) */}
            {
                timelineData.map((stage, index) => (
                    <section className='stage' key={index} ref={_ref}>

                        {/* <div className="dot" /> */}

                        <div className="content">

                            {/* <Paragraph className='year'><time date={stage.year}>{stage.year}</time></Paragraph> */}
                            <Paragraph className='time'>{stage.time}</Paragraph>
                            <Paragraph className='description'>{stage.description}</Paragraph>
                            {
                                stage.images && <div className='images'>
                                    {
                                        stage.images.map((image, index) => (
                                            <div key={`${stage.time}-img-${index}`}>
                                                <Image src={image.src} alt={image.alt} className="stage-image" />
                                            </div>
                                        ))
                                    }
                                </div>
                            }

                        </div>

                    </section>
                ))
            }

        </section>
    );
}

export default Timeline;