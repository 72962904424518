import React from 'react';
import './ContactPage.scss';
import Paragraph from '../components/atoms/Paragraph';
import ContactForm from '../components/templates/ContactForm';
import Card from '../components/molecules/Card';
import ContactIcons from '../components/organisms/ContactIcons';

const ContactPage = () => {
    return (
        <div className='page-container'>
            {/* Introduction text */}
            <section className='contact-header-section' aria-label="Contact Intro">
                <h1>Let's Talk!</h1>
                {/* <Paragraph>
                    Please feel free to drop me a line. Whether you have ideas to share, questions to ask, or even some constructive feed back to dish out :)<br /><br /><br />It’s your move now. Just like a game of chess, we’ll strategize, plan our moves, and make remarkable things happen. Fill out the form below and let’s orchestrate our next brilliant move together. No checkmate required, just a winning collaboration. Looking forward to hearing from you!
                </Paragraph> */}
                <Paragraph>
                    Have ideas, questions, or constructive feedback? I'd love to hear from you! Fill out the form below and let's start a conversation.
                </Paragraph>
            </section>

            {/* Contact form */}
            <ContactForm />

            {/* Contact handles */}
            <section className='contact-handles-section' aira-label='Contact Handles'>
                <Paragraph>You can also connect with me on:</Paragraph>
                <Card className="contact-handles-card">
                    <ContactIcons />
                </Card>
            </section>

        </div>
    );
}

export default ContactPage;