import React from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <footer aria-label="Site footer">
            <small>&copy; Copyright Mohamed Ali Habib {new Date().getFullYear()}</small>
        </footer>
    );
}

export default Footer;