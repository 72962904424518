import React, { useState } from 'react';
import Card from '../molecules/Card';
import './Course.scss';
import Paragraph from '../atoms/Paragraph';
import Image from '../atoms/Image';
import Anchor from '../atoms/Anchor';


const Course = ({ course, certificateLink, offeror, platform, link, numCourses, description }) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const handleHover = () => {
        setIsExpanded(true);
    };

    const handleLeave = () => {
        setIsExpanded(false);
    };

    const handleLinkClick = (e, _link) => {
        if (_link && _link !== '') {
            window.open(_link, '_blank');
        }
    };

    return (
        <Card
            className={`course-card ${isExpanded ? 'expanded' : ''}`}
            events={{
                onMouseEnter: handleHover,
                onMouseLeave: handleLeave,
                onClick: (e) => handleLinkClick(e, certificateLink),
            }}
        >
            {/* image(s): logo(s) of offeror(s)*/}
            <div className="images-container">
                {offeror?.src && <Image src={offeror.src} alt={offeror.name} className="course-image" />}
                {platform?.src && <Image src={platform.src} alt={platform.name} className="course-image" />}
            </div>

            <div className='course-content'>
                {/* title */}
                <h1 className='course-title' onClick={(e) => handleLinkClick(e, link)}>
                    {course}
                </h1>

                {/* offered by & platform */}
                <div className="course-offeror">
                    <Paragraph>
                        Offered by:&nbsp;
                        <Anchor
                            className="offeror-name"
                            href={offeror.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => handleLinkClick(e, offeror.link)}
                        >
                            {offeror.name}
                        </Anchor>
                        {Object.keys(platform).length > 0 && (
                            <>
                                &nbsp;via&nbsp;
                                <Anchor
                                    className="platform-name"
                                    href={platform.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => handleLinkClick(e, platform.link)}
                                >
                                    {platform.name}
                                </Anchor>
                            </>
                        )}
                    </Paragraph>
                </div>

                {/* description */}
                {/* <Paragraph className="course-description">{description}</Paragraph> */}
                <div className="course-description">{description}</div>

                {/* No. of courses */}
                {numCourses > 1 && <Paragraph className="num-courses">{numCourses} Courses</Paragraph>}
            </div>
        </Card >
    );
};

export default Course;