import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import './Input.scss';

const Input = ({ id, name, type, placeholder, value, onChange, required, autocComplete }) => {
//   const inputClasses = classNames('input-field', { required });

  return (
    // <div className={inputClasses}>
    //   <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        autoccomplete={autocComplete}
      />
    // </div>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autocComplete: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default Input;
