// Award.js

import React from 'react';
import './Award.scss';
import Image from '../atoms/Image';
import Paragraph from '../atoms/Paragraph';
import Card from '../molecules/Card';
import Slider from '../molecules/Slider';
import Anchor from '../atoms/Anchor';

const Award = ({
    title,
    images,
    date,
    issuer,
    affiliate,
    link,
    description,
}) => {
    return (
        <Card className="award">
            <div className="award-content">

                {/* title */}
                <h3 className="award-title">{title}</h3>

                {/* issuer and date*/}
                <div className='award-issuer'>
                    <Paragraph className='issuer-prefix'>Issued by: </Paragraph>
                    <Image src={issuer.logoImg} alt={issuer.name} className="issuer-logo" _key={issuer.name} />
                    <Anchor className="issuer-anchor" href={issuer.link} target="_blank" rel="noopener noreferrer">
                        {issuer.name}
                    </Anchor>
                    <Paragraph>in  <time dateTime={date.datetime}>{date.text}</time></Paragraph>
                </div>

                {/* affiliate */}
                {affiliate && (
                    <div className='award-affiliate'>
                        <Paragraph className='affiliate-prefix'>Affiliated with: </Paragraph>
                        <Image src={affiliate.logoImg} alt={affiliate.name} className="affiliate-logo" _key={affiliate.name} />
                        <Anchor className="affiliate-anchor" href={affiliate.link} target="_blank" rel="noopener noreferrer">
                            {affiliate.name}
                        </Anchor>
                    </div>
                )}


                {/* description */}
                <Paragraph className="award-description">
                    {description}
                </Paragraph>

                {link && (
                    <Anchor className="proof-anchor" href={link} target="_blank" rel="noopener noreferrer">
                        Read more
                    </Anchor>)}

            </div>
            <div className='award-images'>
                {/* <Slider images={images} autoplaySpeed={5000} /> */}

                {
                    images?.length > 1 ? (
                        <Slider images={images} autoplaySpeed={5000} />

                    ) : (
                        <Image
                            src={images[0].src}
                            alt={images[0].alt}
                            className="award-image" />
                    )
                }
            </div>
        </Card>
    );
};

export default Award;
