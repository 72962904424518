import React from 'react';

const OrderedList = ({ items }) => {
    return (
        <ol>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ol>
    );
};

export default OrderedList;