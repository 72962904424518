import React from 'react';
import { BiCodeAlt } from 'react-icons/bi';
import SectionHeader from '../molecules/SectionHeader';
import './Projects.scss';
// import Badge from '../molecules/Badge';
// import { BiBrain } from 'react-icons/bi';
import Project from '../organisms/Project';
import './Projects.scss';
import projectsData from '../../data/ProjectsData';

const Projects = () => {
    return (
        <section className='projects-section' aria-label="Projects">

            {/* Section Header */}
            <SectionHeader title="Projects" icon={BiCodeAlt} />

            {/* <div className='temp'>

            <Badge icon={BiBrain} label="Machine Learning" className="ml-badge" />
            </div> */}

            <div className='project-cards-container'>
                {
                    projectsData && projectsData.map((project, index) => (
                        <Project {...project} key={index} />
                    ))
                }
                {/* <Project
                    title="Sarcastic Chatbot"
                    description="A chatbot with a sense of humor"
                    // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium, nibh in imperdiet ultricies, libero lorem euismod arcu, a aliquet arcu diam quis ipsum. Mauris felis turpis, tempus condimentum finibus eu, porta at felis. Phasellus consectetur, est sit amet tincidunt fermentum, lacus mauris volutpat est, non hendrerit eros quam eget neque. Praesent non ex dolor. Mauris tempor nulla purus, ac dictum nulla bibendum et. In dapibus, eros et scelerisque efficitur, nulla magna bibendum massa, sit amet vestibulum magna nibh eleifend purus. Maecenas egestas, libero vitae venenatis varius, orci diam vulputate lectus, nec consectetur lectus nulla vitae lectus. Nulla eu lacus elit."
                    imageSrc="assets/images/chatbot.jpeg"
                    // imageSrc="assets/images/ou-logo.png"
                    // imageSrc="assets/images/ou.jpg"
                    githubLink="https://github.com/MohamedAliHabib/Sarcastic-Chatbot"
                    articleLink="https://medium.com/@mohamedalihabib7/a-deep-learning-sarcastic-chatbot-4c846bca8fd5"
                    badges={['ml']}
                    affiliateImageSrc="assets/images/ou-logo.png"
                /> */}
            </div>


            {/* 
                Projects:

                -RSC
                -Traffic
                -Matt

                -chatgot
                -brain
                -easylearn
                -poc-express-graphql
                
                -android slackworker
                -react trotec
                -vodaphone
                -hubspot
                -android signature

                -webscrapers
            */}

        </section>
    );
}

export default Projects;