import React from 'react';
import './AwardsPage.scss';
import Award from '../components/organisms/Award';
import awardsData from '../data/AwardsData';

const AwardsPage = () => {

    return (
        <div className='page-container'>
            {awardsData.map((award, index) => (
                <Award
                    key={index}
                    {...award}
                />
            ))}
        </div>
    );
}

export default AwardsPage;