import React from 'react';
import Card from '../molecules/Card';
import Image from '../atoms/Image';
import { FaUniversity } from 'react-icons/fa';
import './Degree.scss';
import DateRange from '../molecules/DateRange';
import Paragraph from '../atoms/Paragraph';

const Degree = ({ degree, program, gpa, organization, logo, startDate, endDate, notes, _key }) => {
    return (
        <Card className="degree-card" _key={_key}>

            {/* Logo */}
            <div className='logo-container'>
                {logo ? (
                    <Image src={logo.src} alt={logo.alt} className="school-logo" _key={_key} />
                ) : (
                    <FaUniversity className="default-school-logo" />
                )}
            </div>

            {/* Degree Content */}
            <div className='degree-info'>
                <div className='degree-header'>
                    <div>
                        <h3 className='org-title'>{organization}</h3>
                        <h4 className='degree-title'> {degree} in {program} </h4>
                        {gpa && <h5 className='gpa'><em>GPA: ({gpa})</em></h5>}
                    </div>
                    <DateRange startDate={startDate} endDate={endDate} className="degree-time" ariaLabel="degree-time" />
                </div>
                {notes && <Paragraph className="degree-notes">{notes}</Paragraph>}
            </div>

        </Card>
    );
}

export default Degree;