import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ className, children, events, _key }) => {
    return (
        <div className={className} {...events} key={_key}>
            {children}
        </div>
    );
};

Card.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    events: PropTypes.objectOf(PropTypes.func),
};

export default Card;