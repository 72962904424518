import React from 'react';
import DateRange from './DateRange';
import Anchor from '../atoms/Anchor';
import Paragraph from '../atoms/Paragraph';

const JobRole = ({ title, location, employer, employerLink, time, description, _key }) => (
    <div className="job-info" key={_key}>
        <div className="role-header">
            <h3 className='role-title'>{title}</h3>
            <div>
                <DateRange startDate={time.startDate} endDate={time.endDate} text={time.text} className="job-time" ariaLabel="job-time" />
                <Paragraph className="job-location">,&nbsp;{location}</Paragraph>
            </div>
        </div>
        {
            employerLink ? (
                <Anchor className="job-employer" href={employerLink} target="_blank" rel="noopener noreferrer">
                    {employer}
                </Anchor>
            ) : (
                <Paragraph className="job-employer">{employer}</Paragraph>
            )
        }


        {/* <Paragraph className="job-description">{description}</Paragraph> */}
        <div className="job-description">{description}</div>
    </div>
);

export default JobRole;