import { MdPhoneIphone, MdInsights } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { BiBrain, BiChip } from 'react-icons/bi';
import { FaLaptopCode } from 'react-icons/fa';
import { BsGlobe } from 'react-icons/bs';
import UnorderedList from '../components/atoms/UnorderedList';

const skillsData = [
    {
        title: "Machine Learning",
        icon: BiBrain,
        content: <UnorderedList items={[
            "Machine Learning.",
            "Deep Learning.",
            "Pytorch, Tensorflow, Keras, Scikit-learn.",
        ]} />
    },
    {
        title: "Data Science",
        icon: MdInsights,
        content: <UnorderedList items={[
            "Performing Data Analysis, Manipulating Data, Visualizing Data, and Building Statistical Models.",
            "Expertise in ETL and ELT.",
            "Numpy, Pandas, Matplotlib, Seaborn, Plotly.",
            "Web Scraping",
        ]} />,
    },
    {
        title: "Software Engineering",
        icon: FaLaptopCode,
        content: <UnorderedList items={[
            "Web Development: Frontend and Backend.",
            "Mobile Development.",
            "Languages: Fluent in Python, had experience in JS, Kotlin, and familiar with Java, C++, and Matlab.",
            "Git (Version Control System)",
            "Project Management.",
            "Agile and Scrum Methodologies.",
            "OS: MacOS, Linux, and Windows.",
            "Algorithms and Data Structures.",
        ]} />,
    },
    {
        title: "Web Development",
        icon: BsGlobe,
        content: <UnorderedList items={[
            "FrontEnd: React.JS, SaSS, HTML, CSS, JS, Atomic Design.",
            "Backend: Node.JS, RESTFUL, and GraphQL APIs. Familiar with Typescript.",
        ]} />,
    },
    {
        title: "Mobile Development",
        icon: MdPhoneIphone,
        content: <UnorderedList items={[
            "Android Development: Kotlin and Java.",
        ]} />,
    },
    {
        title: "Computer Vision",
        icon: IoMdEye,
        content: <UnorderedList items={[
            "Image processing, object detection, feature extraction, and CNNs.",
            "OpenCV",
        ]} />,
    },
    {
        title: "Embedded Systems / IoT",
        icon: BiChip,
        content: <UnorderedList items={[
            "Experience with BeagleBone.",
            "Familiar with working with sensors.",
        ]} />,
    },
];

export default skillsData;