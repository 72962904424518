import React from 'react';
import PropTypes from 'prop-types';
import './Alert.scss';
import { MdInfo, MdWarning, MdCheckCircle, MdError } from 'react-icons/md';


const Alert = ({ severity, children }) => {
    let icon;

    switch (severity) {
        case 'info':
            icon = <MdInfo />;
            break;
        case 'warning':
            icon = <MdWarning />;
            break;
        case 'success':
            icon = <MdCheckCircle />;
            break;
        case 'error':
            icon = <MdError />;
            break;
        default:
            icon = null;
    }

    return (
        <div className={`alert alert-${severity}`}>
            <div className="alert-icon">{icon}</div>
            <div className="alert-content">
                {children}
            </div>

        </div>
    );
};

Alert.propTypes = {
    severity: PropTypes.oneOf(['info', 'warning', 'success', 'error']).isRequired,
    children: PropTypes.node.isRequired,
};

export default Alert;