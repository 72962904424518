import React from 'react';
import { RiToolsLine } from 'react-icons/ri';
import SectionHeader from '../molecules/SectionHeader';
import Skill from '../organisms/Skill';
import './Skills.scss';
import skillsData from '../../data/SkillsData';

const Skills = () => {
    return (
        <section className='skills-section' aria-label="Skills">

            {/* Section Header */}
            <SectionHeader title="Skills" icon={RiToolsLine} />

            {/* Skill Cards */}
            <div className='skill-cards-container'>
                {
                    skillsData && skillsData.map((skill, index) => (
                        <Skill
                            key={index}
                            title={skill.title}
                            icon={skill.icon}
                            content={skill.content}
                        />
                    ))
                }
            </div>

        </section>
    );
}

export default Skills;