import React from 'react';
import './Experience.scss';
import SectionHeader from '../molecules/SectionHeader';
import { MdWork } from 'react-icons/md';
import Job from '../organisms/Job';
import { ouPositions, salvimarPositions, wizmePositions } from '../../data/ExperienceData';

const Experience = () => {
    return (
        <section className='experience-section' aria-label="Job Experience">

            {/* Section Header */}
            <SectionHeader title="Experience" icon={MdWork} />

            {/* OU */}
            <Job
                positions={ouPositions}
                employer="University of Oklahoma"
                employerLink="https://www.ou.edu/"
                employerLogo="assets/images/ou-logo.png"
                employerLogoAlt="University Oklahoma - interlocking logo"
            />


            {/* Salvimar */}
            <Job
                positions={salvimarPositions}
                employer="Salvimar"
            />

            {/* Wizme */}
            <Job
                positions={wizmePositions}
                employer="Wizme Ltd"
                employerLogo="assets/images/wizme-logo.jpeg"
                employerLogoAlt="Wizme Ltd logo"
            />


        </section>
    );
};

export default Experience;