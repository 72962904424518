
/* ITS Heartland 2022 */
/* KDnuggets top blogger 2019 */
/* Wikilogia Hackathon 2018 */
const awardsData = [
    {
        title: " Oklahoma Transportation Research Day 2023",
        images: [
            // { src: "assets/images/", alt: "OTRD Award" },
            { src: "assets/images/otrd-poster-2023.png", alt: "OTRD Winner Poster" },
        ],
        date: { dateTime: "2023-10", text: "Oct 2023" },
        issuer: { name: "The Oklahoma Department of Transportation (ODOT)", logoImg: "assets/images/odot-logo.png", link: "https://oklahoma.gov/odot.html" },
        affiliate: { name: "University of Oklahoma", logoImg: "assets/images/ou-logo.png", link: "https://ou.edu/" },
        link: "https://www.sptc.org/otrd-2023#:~:text=Oklahoma%20Transportation%20Research%20Day%202023&text=This%20event%20was%20held%20at,Professional%20Development%20Hours%20(PDHs).",
        description:
            <>
                3rd Place Winner, OTRD Student Poster Competition 2023 in Oklahoma City, Oklahoma.
                <br /><br />Poster title: The Importance of RWIS Subsurface Temperature Probes.
            </>,
    },
    {
        title: "ITS HEARTLAND 2022",
        images: [
            { src: "assets/images/its-heartland-award.jpeg", alt: "ITS Heartland Award" },
            { src: "assets/images/its-heartland-poster.jpg", alt: "ITS Heartland Poster" },
        ],
        date: { dateTime: "2022-04", text: "April 2022" },
        issuer: { name: "ITS Heartland", logoImg: "assets/images/its-heartland-logo.png", link: "https://itsheartland.org/" },
        affiliate: { name: "University of Oklahoma", logoImg: "assets/images/ou-logo.png", link: "https://ou.edu/" },
        link: "https://itsheartland.org/2022-annual-meeting/",
        description:
            <>
                3rd Place Winner, ITS Heartland Student Poster Competition 2022 Annual Meeting in Lincoln Nebraska.
                <br /><br />Poster title: Speed and Travel Time Estimation Analysis of Traffic Systems.<br/>A project in collaboration with the Oklahoma Department of Transportation (ODOT)
            </>,
    },
    {
        title: "Wikilogia Hackathon | Data Science and Machine Learning",
        images: [
            { src: "assets/images/wikilogia-award.png", alt: "Wikilogia Award" },
        ],
        date: { dateTime: "2018-04", text: "April 2018" },
        issuer: { name: "Wikilogia Hackathon", logoImg: "assets/images/wikilogia-logo.png", link: "https://wikilogia.org/en/" },
        // link: "https://m.facebook.com/wikilogia/posts/1966604256746076/",
        description:
            <>
                1st place winner. Wikilogia Hackathon is a project-based programming competition with four paths: Data Science and Machine Learning, Game Development, Electronics, and Web and Mobile.
                <br /><br />Our project, easyLearn, is an educational blogging website with an Arabic Text recommender system. We employed Natural Language Processing and Machine Learning techniques to build this system. We utilized Python, Django, and HTML+CSS+JavaScript to develop the project.
            </>,
    },
    {
        title: "KDnuggets Most Popular Post",
        images: [
            { src: "assets/images/kdnuggets-award.png", alt: "KDnuggets Award" },
        ],
        date: { dateTime: "2019-09", text: "Sep 2019" },
        issuer: { name: "KDnuggets", logoImg: "assets/images/kdnuggets-logo.svg", link: "https://www.kdnuggets.com/" },
        link: "https://www.kdnuggets.com/2019/10/top-stories-2019-sep.html",
        description: `
        Awarded a golden badge for the article: "Advice on building a machine learning career and reading research papers by Prof. Andrew Ng"
        `,
    },
];


export default awardsData;