import { addUniqueKeyToList } from "../utils/helper";
import OrderedList from '../components/atoms/OrderedList';

const coursesData = [
    {
        course: "Deep Learning Specialization",
        certificateLink: "https://www.coursera.org/account/accomplishments/specialization/4LSX9XM2U7BX",
        offeror: { name: "DeepLearning.AI", link: "https://www.deeplearning.ai/", src: "assets/images/deeplearningai.png" },
        platform: { name: "Coursera", link: "https://www.coursera.org/", src: "assets/images/coursera.png" },
        link: "https://www.coursera.org/specializations/deep-learning",
        numCourses: 5,
        description: <OrderedList items={[
            "Neural Networks and Deep Learning",
            "Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization",
            "Structuring Machine Learning Projects",
            "Convolutional Neural Networks",
            "Sequence Models",
        ]}/>
    },
    {
        course: "Machine Learning Specialization",
        certificateLink: "https://www.coursera.org/account/accomplishments/specialization/TRCVNSN68249",
        offeror: { name: "University of Washington", link: "https://www.washington.edu/", src: "assets/images/uw.png" },
        platform: { name: "Coursera", link: "https://www.coursera.org/", src: "assets/images/coursera.png" },
        link: "https://www.coursera.org/specializations/machine-learning",
        numCourses: 4,
        description: <OrderedList items={[
            "Machine Learning Foundations: A Case Study Approach",
            "Machine Learning: Regression",
            "Structuring Machine Learning Projects",
            "Machine Learning: Classification",
            "Machine Learning: Clustering & Retrieval",
        ]}/>
    },
    {
        course: "Machine Learning",
        certificateLink: "https://www.coursera.org/account/accomplishments/verify/7Q74LC79T3LF",
        offeror: { name: "Stanford University", link: "https://www.stanford.edu/", src: "assets/images/stanford.jpeg" },
        platform: { name: "Coursera", link: "https://www.coursera.org/", src: "assets/images/coursera.png" },
        link: "https://www.coursera.org/learn/machine-learning",
        numCourses: 1,
        description: "The well-known Andrew Ng's course.",
    },
    {
        course: "Applied Machine Learning in Python",
        certificateLink: "https://www.coursera.org/account/accomplishments/verify/L47DG6ECLJB6",
        offeror: { name: "University of Michigan", link: "https://umich.edu/", src: "assets/images/umich.png" },
        platform: { name: "Coursera", link: "https://www.coursera.org/", src: "assets/images/coursera.png" },
        link: "https://www.coursera.org/learn/python-machine-learning",
        numCourses: 1,
        description: "",
    },
    {
        course: "Applied Plotting, Charting & Data Representation in Python",
        certificateLink: "https://www.coursera.org/account/accomplishments/verify/KWGWQ2TARAH2",
        offeror: { name: "University of Michigan", link: "https://umich.edu/", src: "assets/images/umich.png" },
        platform: { name: "Coursera", link: "https://www.coursera.org/" },
        link: "https://www.coursera.org/learn/python-plotting",
        numCourses: 1,
        description: "",
    },
    {
        course: "Introduction to Data Science in Python",
        certificateLink: "https://www.coursera.org/account/accomplishments/verify/PBU3FKDNU5CR",
        offeror: { name: "University of Michigan", link: "https://umich.edu/", src: "assets/images/umich.png" },
        platform: { name: "Coursera", link: "https://www.coursera.org/", src: "assets/images/coursera.png" },
        link: "https://www.coursera.org/learn/python-data-analysis",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 59731-981 Artificial Neural Networks and Applications",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 5123-980 Wireless Communication",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 5970-980 Complex Systems and Networks",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "CS 5173-995 Computer Security",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 5973-980 Computer Vision",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 5970-980 Machine Learning",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 5583-980 Information Theory and Probabilistic Programming",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
    {
        course: "ECE 5583-980 Digital Signal Processing",
        offeror: { name: "University of Oklahoma", link: "https://ou.edu/", src: "assets/images/ou-logo.png" },
        certificateLink: "",
        platform: {},
        link: "",
        numCourses: 1,
        description: "",
    },
];

export default coursesData;