import React, { useState } from 'react';
import Timeline from '../components/templates/Timeline';
import './AboutPage.scss';

import {useRef, useEffect} from 'react';


const AboutPage = () => {

    const timelineRef = useRef();
    const [isStageVisible, setIsStageVisible] = useState();
    console.log(isStageVisible);
    useEffect(() => {
        // console.log(timelineRef);
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setIsStageVisible(entry.isIntersecting);
        });
        observer.observe(timelineRef.current);
    }, [timelineRef]);

    return (
        <div className='page-container'>
            <Timeline _ref={timelineRef} />
        </div>
    );
}

export default AboutPage;