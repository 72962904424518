import React, { useState, useEffect } from 'react';
import NavigationMenu from '../organisms/NavigationMenu';
import './Header.scss';
import Button from '../atoms/Button';
import { RiMenu4Fill } from 'react-icons/ri';

const Header = () => {

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const hideMenu = () => {
        setIsVisible(false);
    };

    return (
        <header>
            {/* <h1>Mohamed Ali Habib (logo)</h1> */}
            <NavigationMenu id="nav-menu" isVisible={isVisible} hideMenu={hideMenu}/>
            <Button
                className="menu-toggle-btn"
                aria-label="Toggle navigation menu"
                aria-controls="nav-menu"
                onClick={toggleVisibility}
            >
                <RiMenu4Fill />
            </Button>
        </header>
    )
};

export default Header;