import { MdInsights, MdAndroid } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { BiBrain, BiChip } from 'react-icons/bi';
import { FaLaptopCode, FaLanguage } from 'react-icons/fa';
import { BsGlobe } from 'react-icons/bs';

const badgesData = {
    "ml": { label: "ML", icon: BiBrain },
    "ds": { label: "DS", icon: MdInsights },
    "nlp": { label: "NLP", icon: FaLanguage },
    "software": { label: "Sofware", icon: FaLaptopCode },
    "web": { label: "Web", icon: BsGlobe },
    "android": { label: "Android", icon: MdAndroid },
    "vision": { label: "Vision", icon: IoMdEye },
    "iot": { label: "IoT", icon: BiChip },
};

const getBadge = (badgeKey) => {
    return badgesData[badgeKey] || null;
};

const projectsData = [
    {
        title: "Travel Time Estimation Modeling Using Radar Sensor Data",
        description: "ML models to estimate travel time using radar speed data and utilizing HERE speed data as ground truth. The project encompasses a statistical analysis of speed, travel time, and volume of 4 traffic systems (radar, AVC, INRIX, HERE) deployed in Oklahoma.",
        imageSrc: "assets/images/traffic-modeling.jpg",
        githubLink: "",
        articleLink: "",
        badges: [getBadge('ml'), getBadge('ds')],
        affiliateImageSrc: "assets/images/ou-logo.png",
    },
    {
        title: "MATT: Real-time Android App for snowplow trucks",
        description: "An IoT real-time Android application in ODOT snowplow trucks enabling seamless communication between drivers and operators and collecting data from embedded systems. MATT (Maintenance Activity Truck Tracking)",
        imageSrc: "assets/images/matt.jpg",
        githubLink: "",
        articleLink: "",
        badges: [getBadge('android'), getBadge('iot')],
        affiliateImageSrc: "assets/images/ou-logo.png",
    },
    {
        title: "Sarcastic Chatbot",
        description: "A chatbot with a sense of humor. An encoder-decoder (LSTM) architecture with Bahdanau\’s attention.",
        imageSrc: "assets/images/chatbot.jpeg",
        githubLink: "https://github.com/MohamedAliHabib/Sarcastic-Chatbot",
        articleLink: "https://medium.com/@mohamedalihabib7/a-deep-learning-sarcastic-chatbot-4c846bca8fd5",
        badges: [getBadge('ml'), getBadge('nlp')],
        // affiliateImageSrc: "assets/images/ou-logo.png",
    },
    {
        title: "Brain Tumor Detection",
        description: "A convolutional neural network to classify brain tumors into malignant and benign.",
        imageSrc: "assets/images/brain_convnet.jpg",
        githubLink: "https://github.com/MohamedAliHabib/Brain-Tumor-Detection",
        articleLink: "https://medium.com/@mohamedalihabib7/brain-tumor-detection-using-convolutional-neural-networks-30ccef6612b0",
        badges: [getBadge('ml'), getBadge('vision')],
    },
    {
        title: "Recommender System for Articles",
        description: "A Doc2Vec and Word2Vec (Skip-Gram) model using Gensim library for recommending articles in the Arabic language.",
        imageSrc: "assets/images/easylearn.png",
        githubLink: "https://github.com/MohamedAliHabib/easyLearn-Arabic-Text-Recommender-System",
        articleLink: "",
        badges: [getBadge('ml'), getBadge('nlp')],
    },
];

export default projectsData;