import React from 'react';
import './NewsTicker.scss';
import Icon from '../atoms/Icon';
import { FaBullhorn } from 'react-icons/fa';
import announcement from '../../data/Announcement';

const NewsTicker = () => {
    return (<div className='news-ticker'>
        <Icon icon={FaBullhorn} className="news-ticker-icon" />
        <div className="news-ticker-text">
            <span className="announcement">{announcement}</span>
        </div>
    </div>);
}

export default NewsTicker;