import React from 'react';
// import { HomePage, AboutPage, ContactPage, AwardsPage, TestimonialsPage, MiscellaneousPage } from './pages';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import AwardsPage from './pages/AwardsPage';
import TestimonialsPage from './pages/TestimonialsPage';
import MiscellaneousPage from './pages/MiscellaneousPage';

const routes = [
    { path: '/', element: <HomePage />, exact: true },
    { path: '/about', element: <AboutPage /> },
    { path: '/contact', element: <ContactPage /> },
    { path: '/awards', element: <AwardsPage /> },
    { path: '/testimonials', element: <TestimonialsPage /> },
    { path: '/miscellaneous', element: <MiscellaneousPage /> }
];

export default routes;