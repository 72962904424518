import React from 'react';
import PropTypes from 'prop-types';

const Anchor = ({href, target, rel, className, children, onClick, _key}) => {
    return ( 
        <a href={href} className={className} target={target} rel={rel} onClick={onClick} key={_key}>
            {children}
        </a>
     );
};

Anchor.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Anchor;