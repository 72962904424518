import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ icon: IconComponent, className }) => {
    // return <IconComponent className={`icon ${className}`} />;
    return <IconComponent className={className} />;
};

Icon.propTypes = {
    icon: PropTypes.elementType.isRequired,
    className: PropTypes.string,
};

export default Icon;