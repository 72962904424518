const presentationsData = [
    {
        url: "https://www.slideshare.net/MohamedAliHabib3/objectdetectionpptx-258520486",
        embed: <iframe
            key={0}
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            src="https://www.slideshare.net/slideshow/embed_code/key/5T2YSVNIhIFQpe?hostedIn=slideshare&page=upload"
            width="476" height="400" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no"></iframe>,
    },
    {
        ur: "https://www.slideshare.net/MohamedAliHabib3/convnetspptx-258520528",
        embed: <iframe
            key={1}
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            src="https://www.slideshare.net/slideshow/embed_code/key/NFmU3SuuKPT45F?hostedIn=slideshare&page=upload"
            width="476" height="400" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no"></iframe>,
    },
    {
        ur: "https://www.slideshare.net/MohamedAliHabib3/ngboostpptx-258520540",
        embed:
            <iframe
                key={2}
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                src="https://www.slideshare.net/slideshow/embed_code/key/D3jAsOyinLWtlM?hostedIn=slideshare&page=upload"
                width="476" height="400" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no"></iframe>,
    }
];

export default presentationsData;