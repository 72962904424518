import React from 'react';
import { GiBookshelf } from 'react-icons/gi';
import SectionHeader from '../molecules/SectionHeader';
import './Courses.scss';
import Course from '../organisms/Course';
import coursesData from '../../data/CoursesData';


const Courses = () => {
    return (
        <section className='courses-section' aria-label="Courses">

            {/* Section Header */}
            <SectionHeader title="Courses" icon={GiBookshelf} />

            {/* Courses */}
            <div className='courses-container'>
                {
                    coursesData.map((course, index) => (
                        <Course key={index} {...course} />
                    ))
                }
            </div>

        </section>
    );
}

export default Courses;