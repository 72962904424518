import React from 'react';
import './SectionHeader.scss';
import Icon from '../atoms/Icon';

const SectionHeader = ({ title, icon, className }) => {
    return (
        <div className={`section-header ${className ? className : ''}`}>
            {icon && <Icon icon={icon} className="header-icon" />}
            <h2 className='header-title'>{title}</h2>
        </div>
    );
}

export default SectionHeader;