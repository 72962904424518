import React from 'react';
import PropTypes from 'prop-types';

const Span = ({children, className}) => {
    return <span className={className}>{children}</span>;
};

Span.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Span;