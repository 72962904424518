// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/templates/Header';
import Footer from './components/templates/Footer';
import routes from './Routes';
import './assets/styles/styles.scss';
// import { ThemeContext } from './ThemeContext';

const App = () => {
  // const { theme } = useContext(ThemeContext);

  return (
    // className={`app ${theme === 'light' ? 'light-theme' : 'dark-theme'}`}
    <Router>
      <>
        <Header />
        <main>
          <Routes>
            {routes.map(({ path, element, exact }) => (
              <Route key={path} path={path} element={element} exact={exact} />
            ))}
          </Routes>
        </main>
        <Footer />
      </>
    </Router>
  );
}

export default App;
