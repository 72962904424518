import React from 'react';
import { IoMdPaper } from 'react-icons/io';
import SectionHeader from '../molecules/SectionHeader';
import './Presentations.scss';
import presentationsData from '../../data/PresentationsData';

const Presentations = () => {
    return (
        <section className='presentations-section' aria-label="Presentations">

            {/* Section Header */}
            <SectionHeader title="Presentations" icon={IoMdPaper} />
            <div className='slides-container'>
                {
                    presentationsData.map((slides, index) => (
                        slides.embed
                    ))
                }
            </div>

        </section>
    );
}

export default Presentations;