import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, _key, className, events }) => {
  return <img src={src} alt={alt} className={className} key={_key} {...events} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  key: PropTypes.string,
  className: PropTypes.string,
  events: PropTypes.objectOf(PropTypes.func),
};

export default Image;