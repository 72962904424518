import React from 'react';
import Icon from '../atoms/Icon';
import PropTypes from 'prop-types';
import Span from '../atoms/Span';
import './Badge.scss';

const Badge = ({ icon, label, className }) => {
    return (
        <div className={`badge ${className}`}>
            <Icon icon={icon} className="badge-icon"/>
            <Span className="badge-label">{label}</Span>
        </div>
    );
};

Badge.propTypes = {
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Badge;