import React, { useState, useEffect } from 'react';
import './LoadingScreen.scss';

const LoadingScreen = ({ onVideoEnd }) => {
    const [videoEnded, setVideoEnded] = useState(false);

    useEffect(() => {
        const videoElement = document.getElementById('loading-video');

        const handleVideoEnd = () => {
            setVideoEnded(true);
            onVideoEnd(); // Callback to notify the parent component that the video has ended
        };

        videoElement.addEventListener('ended', handleVideoEnd);

        return () => {
            videoElement.removeEventListener('ended', handleVideoEnd);
        };
    }, [onVideoEnd]);

    return (
        <div className="loading-screen">
            <video id="loading-video" autoPlay muted playsInline>
                <source src="assets/video/loading.mp4" type="video/mp4" />
            </video>
        </div>
    );
};

export default LoadingScreen;
