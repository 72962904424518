import React from 'react';
import Anchor from '../atoms/Anchor';

const IconAnchor = ({ icon, link, target, rel, className, _key }) => {
    return (
        <Anchor href={link} className={className} target={target} rel={rel} _key={_key}>
            {icon}
        </Anchor>
    );
};

export default IconAnchor;