import React from 'react';
import './TextArea.scss';
import PropTypes from 'prop-types';


const TextArea = ({ id, name, value, onChange, placeholder, required, className, autocComplete }) => {
    return (
        <textarea
            id={id}
            name={name}
            className={className}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            autoccomplete={autocComplete}
        />
    );
};

TextArea.propTypes = {
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    autocComplete: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
};

export default TextArea;
