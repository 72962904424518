import React from 'react';
import SectionHeader from '../molecules/SectionHeader';
import './Education.scss';
import { FaUniversity } from 'react-icons/fa';
import Degree from '../organisms/Degree';

const Education = () => {
    return (
        <section className='education-section' aria-label="Education">

            {/* Section Header */}
            <SectionHeader title="Education" icon={FaUniversity} />

            {/* Master of Science in ECE @ OU */}
            <Degree
                degree="Master of Science"
                program="Electrical and Computer Engineering"
                organization="University of Oklahoma"
                logo={{ src: "assets/images/ou-logo.png", alt: "University of Oklahoma Logo" }}
                gpa="4.0/4.0"
                startDate={{ date: "2021-08", text: "Aug 2021" }}
                endDate={{ date: "2023-12", text: "Dec 2023" }}
                _key="ou-ece"
                // endDate={null}
            />

            {/* Bachelor's Degree in Computer Science */}
            <Degree
                degree="Bachelor's Degree"
                program="Computer Science"
                organization="University of Aleppo"
                logo={{ src: "assets/images/bach-uni.jpeg", alt: "University of Aleppo Logo" }}
                // gpa="4.0/4.0"
                startDate={{ date: "2014", text: "2014" }}
                endDate={{ date: "2019", text: "2019" }}
                notes="5-year degree program. Top 5%"
                _key="al-cs"
            />

        </section>
    );
}

export default Education;