import React, { useState, useEffect } from 'react';
import './ContactForm.scss';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import TextArea from '../atoms/TextArea';
import axios from 'axios';
import Paragraph from '../atoms/Paragraph';
import Alert from '../molecules/Alert';

// TODO: hide this and update cors at api gateway in aws to only accept mohamedalihabib.com as origin for requests
const API_URL = 'https://yuc16vk508.execute-api.us-east-2.amazonaws.com/contactali';

const ContactForm = () => {
    const [isPending, setIsPending] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [message, setMessage] = useState('');
    const [showFailureAlert, setShowFailureAlert] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleOrganizationChange = (e) => {
        setOrganization(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const clearFields = () => {
        setName('');
        setEmail('');
        setOrganization('');
        setMessage('');
    };

    useEffect(() => {
        if (name && email && message)
            setCanSubmit(true);
        else
            setCanSubmit(false);
    }, [name, email, message]);

    // useEffect(() => {
    //     if (showFailureAlert) {

    //     }
    // }, showFailureAlert)

    const handleResponse = (response) => {
        // if (response && response.message & response.message.includes('Required field')) {
        //     // Extract the missing field from the error message
        //     const missingField = response.message.split('`')[1];
        //     console.log(`Required field '${missingField}' is missing.`);
        // } else {
        switch (response?.message) {
            case "success":
                console.log('yay!');
                setIsSubmitted(true);
                break;
            case "failure":
                console.log('nay!');
                setIsSubmitted(false);
                setShowFailureAlert(true);
                // Hide the alert after 3 seconds
                // const timer = setTimeout(() => {
                setTimeout(() => {
                    setShowFailureAlert(false);
                }, 3000);
                break;
            default:
                break;
        }
        // }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                name: name.trim(),
                email: email.trim(),
                organization: organization.trim(),
                message: message.trim(),
            };
            setIsPending(true);
            const response = await axios.post(API_URL, formData);
            console.log('Response:', response.data);
            setIsPending(false);
            handleResponse(response.data);
        } catch (error) {
            console.error('Error:', error);
            setIsPending(false);
        }
        clearFields();
    }

    return (
        isSubmitted ? (
            <>
                <h1 className='thank-you-header'>Thank you!</h1>
                <Paragraph>I will try to get back to you as soon as possible!</Paragraph>
            </>
        ) : (
            <section className='contact-form-section' aria-label='Contact Form'>
                {isPending && <div className="loading-line"></div>}

                <form className="contact-form" onSubmit={handleSubmit}>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={handleNameChange}
                        required
                        autocComplete="name"
                    />
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        autocComplete="email"
                    />
                    <Input
                        id="organization"
                        name="organization"
                        type="text"
                        placeholder="Organization (Optional)"
                        value={organization}
                        onChange={handleOrganizationChange}
                        autocComplete="organization"
                    />
                    <TextArea
                        id="message"
                        name="message"
                        type="text"
                        placeholder="Message"
                        value={message}
                        onChange={handleMessageChange}
                        required
                        autocComplete="off"
                    />
                    <Button label="Submit" type="submit" className="btn-submit" disabled={!canSubmit} />
                </form>

                {showFailureAlert && <Alert severity="error">
                    <div className="alert-title">Error</div>
                    <div>Oops :( Something went wrong — <strong>Please try again!</strong></div>
                </Alert>}

            </section>
        )
    );
}

export default ContactForm;