import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, onClick, className, disabled, children }) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {label && label}
      {children && children}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;