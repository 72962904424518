import React from 'react';
import './Job.scss';
// import PropTypes from 'prop-types';
import Card from '../molecules/Card';
import Image from '../atoms/Image';
import { BsFillBuildingsFill } from 'react-icons/bs';
import JobRole from '../molecules/JobRole';

const Job = ({ positions: roles, employer, employerLink, employerLogo, employerLogoAlt }) => {
    return (
        <Card className="job-card" _key={employer}>

            <div className='logo-container'>
                {employerLogo ? (
                    <Image src={employerLogo} alt={employerLogoAlt} className="employer-logo" />
                ) : (
                    <BsFillBuildingsFill className="default-logo" />
                )}
            </div>


            <div className="roles">
                {roles.map((job, index) => (
                    <div key={index}>
                        <JobRole {...job} employer={employer} employerLink={employerLink} _key={index} />
                        {index < roles.length - 1 && <hr className="job-separator" />}
                    </div>
                ))}
            </div>
        </Card>
    );
};

// Job.propTypes = {
//     positions: PropTypes.arrayOf(
//         PropTypes.shape({
//             title: PropTypes.string.isRequired,
//             employer: PropTypes.string.isRequired,
//             employerLogo: PropTypes.string,
//             employerLogoAlt: PropTypes.string,
//             time: PropTypes.string.isRequired,
//             location: PropTypes.string.isRequired,
//             description: PropTypes.string.isRequired,
//         })
//     ).isRequired,
// };

export default Job;