import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from '../atoms/Image';
import './Slider.scss';

const Slider = ({ images, autoplaySpeed }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        }, autoplaySpeed);

        return () => clearInterval(interval);
    }, [images, autoplaySpeed]);

    const activeImage = images[currentSlide];

    return (
        images?.length > 1 ? (
            <>
                {activeImage && (
                    <Image
                        _key={activeImage.src}
                        src={activeImage.src}
                        alt={activeImage.alt}
                        className="slider-image"
                    />
                )}
            </>

        ) : (
            <Image
                src={images[0].src}
                alt={images[0].alt}
                className="slider-image" />
        )
    );
};

Slider.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        })
    ).isRequired,
    autoplaySpeed: PropTypes.number.isRequired,
};

export default Slider;
